body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout-content {
  min-height: 800px;
}

/* 整个滚动条 */
div::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* 滚动条有滑块的轨道部分 */
div::-webkit-scrollbar-track-piece {
  background-color: transparent;
  border-radius: 5px;
}

/* 滚动条滑块(竖向:vertical 横向:horizontal) */
div::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #f2f2f2;
  border-radius: 5px;
}

/* 滚动条滑块hover */
div::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

/* 同时有垂直和水平滚动条时交汇的部分 */
div::-webkit-scrollbar-corner {
  display: block; /* 修复交汇时出现的白块 */
}

